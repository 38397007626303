import React from "react";
import "./Testimonials.css";
import { Swiper, SwiperSlide } from "swiper/react";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg";
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";
import { useContext } from "react";
import { themeContext } from "../../Context";

import SwiperCore, { Navigation, Pagination } from "swiper";

import "swiper/css/pagination";
import "swiper/css";

SwiperCore.use([Navigation, Pagination]);


const Testimonials = () => {
   const theme = useContext(themeContext);
   const darkMode = theme.state.darkMode;
  const clients = [
    {
      img: profilePic1,
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae tempora placeat aliquam non repellendus? Sequi ad, repudiandae, rem veniam eos quis sint eius explicabo velit dolorem ducimus expedita provident omnis.",
    },
    {
      img: profilePic2,
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae tempora placeat aliquam non repellendus? Sequi ad, repudiandae, rem veniam eos quis sint eius explicabo velit dolorem ducimus expedita provident omnis.",
    },
    {
      img: profilePic3,
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae tempora placeat aliquam non repellendus? Sequi ad, repudiandae, rem veniam eos quis sint eius explicabo velit dolorem ducimus expedita provident omnis.",
    },
    {
      img: profilePic4,
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae tempora placeat aliquam non repellendus? Sequi ad, repudiandae, rem veniam eos quis sint eius explicabo velit dolorem ducimus expedita provident omnis.",
    }
  ];
  return (
    <div className="t-wrapper">
      <div className="t-heading">
        <span>Clients always get</span>
        <span> Exceptional Work</span>
        <span> from me...</span>
        <div
          className="blur t-blur1"
          style={{ background: "var(--purple)" }}
        ></div>

        <div className="blur t-blur2" style={{ background: "skyblue" }}></div>
      </div>

      <Swiper
        modules={{ Pagination }}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        navigation={true}
      >
        {clients.map((clients, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={clients.img} alt="" />
                <span style={{ color: darkMode ? "white" : "" }}>
                  {clients.review}
                </span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonials;
