import React from "react";
import "./Intro.css";
import  Github from '../../img/github.png'
import LinkedIn from "../../img/linkedin.png";
import Instagram from "../../img/instagram.png";
import Vector1 from '../../img/Vector1.png'
import Vector2 from '../../img/Vector2.png'
import boy from '../../img/boy.png'
import thumbup from "../../img/thumbup.png";
import glassesimoji from "../../img/glassesimoji.png";
import Crown from '../../img/crown.png'
import FloatingDiv from "../FloatingDiv/FloatingDiv";
import { themeContext } from "../../Context";
import { useContext } from "react";
import {motion} from 'framer-motion'


const Intro = () => {
  const transition={ duration :3,type :"spring"}
   const theme = useContext(themeContext);
   const darkMode = theme.state.darkMode;
  return (
    <div className="intro">
      <div className="i-left">
        <div className="i-name">
          <span style={{ color: darkMode ? "white" : "" }}>Hi! I Am</span>
          <span> Andrews Jayholy</span>
          <span style={{ color: darkMode ? "white" : "" }}>
            Frontend Developer with enough experience in web designing and
            development, producing Quality Work
          </span>
        </div>
        <button className="i-button button"> Hire Me</button>
        <div className="intro-icons">
          <a target="_blank" href="https://github.com/jayholy">
            <img src={Github} alt="" />
          </a>
          <a target="_blank" href="https://twitter.com/90es_k">
            <img src={Instagram} alt="" />
          </a>
          <a target="_blank" href="https://github.com/jayholy">
            <img src={LinkedIn} alt="" />
          </a>
        </div>
      </div>
      <div className="i-right">
        <img src={Vector1} alt="" />
        <img src={Vector2} alt="" />
        <img src={boy} alt="" />
        <motion.img
          initial={{ left: "-40%" }}
          whileInView={{ left: "-15%" }}
          transition={transition}
          src={glassesimoji}
          alt=""
        />
        <motion.div
          initial={{ left: "20%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          style={{ top: "-4%", left: "68%" }}
          className="floating-div"
        >
          <FloatingDiv image={Crown} txt1="Web" txt2="Developer" />
        </motion.div>
        <motion.div
          initial={{ left: "20%" }}
          whileInView={{ left: "3%" }}
          transition={transition}
          style={{ top: "18rem", left: "01rem" }}
          className="floating-div"
        >
          <FloatingDiv
            style={{ color: darkMode ? "white" : "" }}
            image={thumbup}
            txt1="Best Design"
            txt2="Alo Award"
          />
        </motion.div>
        <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blur"
          style={{
            background: "#c1f5ff",
            top: "17rem",
            width: "22rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Intro;
