import React from "react";
import "./Experience.css";

const Experience = () => {
  return (
    <div className="experience">
      <div className="achievement">
        <div className="circle">2+</div>
        <span>years</span>
        <span> Experience</span>
      </div>
      <div className="achievement">
        <div className="circle">10+</div>
        <span>completed</span>
        <span> Projects</span>
      </div>{" "}
      <div className="achievement">
        <div className="circle">4+</div>
        <span>companies</span>
        <span>Work</span>
      </div>
    </div>
  );
};

export default Experience;
