import React from "react";
import "./Card.css";
import { useContext } from "react";
import { themeContext } from "../../Context";

const Card = ({emoji, heading, detail}) => {
   const theme = useContext(themeContext);
   const darkMode = theme.state.darkMode;
  return (
    <div className="card">
      <img src={emoji} alt="" />
      <span style={{ color: darkMode ? "white" : "" }}> {heading}</span>
      <span style={{ color: darkMode ? "white" : "" }}>{detail}</span>
      <button
        style={{ background: darkMode ? "black" : "" }}
        className="c-button"
      >
        LEARN MORE &#8594;
      </button>
    </div>
  );
};

export default Card;
