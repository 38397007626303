import React from "react";
import "./Services.css";
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import Card from "../Card/Card";
import Resume from './resume.pdf';
import { themeContext } from "../../Context";
import { useContext } from "react";
import { motion } from "framer-motion";


const Services = () => {
   const transition = { duration: 2, type: "spring" };
  
   const theme = useContext(themeContext);
   const darkMode = theme.state.darkMode;
  return (
    <div className="services">
      <div className="awesome">
        <span style={{ color: darkMode ? "white" : "" }}>My Awesome</span>
        <span> services</span>
        <span style={{ color: darkMode ? "white" : "" }}>
          {" "}
          Lorem ipsum is simply a dummy text of printing of printing Lorem ipsum{" "}
          <br />
          Lorem ipsum is simply a dummy text of printing{" "}
        </span>
        <a href={Resume} download>
          <button className="button s-button"> Download CV</button>
        </a>
        <div className="blur s-blur" style={{ background: "#abf1ff94" }}></div>
      </div>
      <div className="cards">
        <motion.div
          initial={{ left: "20rem" }}
          whileInView={{ left: "25rem" }}
          transition={transition}
          style={{ left: "14rem" }}
        >
          <Card
            emoji={HeartEmoji}
            heading={"Design"}
            detail={"Figam, Sketch, Maya, Adobe xd, Photoshop"}
          />
        </motion.div>
        <motion.div
          initial={{ left: "20%" }}
          whileInView={{ left: "-4rem" }}
          transition={transition}
          style={{ left: "-4rem", top: "12rem" }}
        >
          <Card
            emoji={Glasses}
            heading={"Developer"}
            detail={"Html, Css, Figam, Sketch, Maya,JavaScript, React"}
          />
        </motion.div>
        <motion.div
          initial={{ left: "20%" }}
          whileInView={{ left: "16rem" }}
          transition={transition}
          style={{ left: "16rem", top: "19rem" }}
        >
          <Card
            emoji={Humble}
            heading={"UI/UX"}
            detail={
              "Lorem ipsum is simply a dummy text of printing of printing"
            }
          />
        </motion.div>
        <div
          className="blur s-blur1"
          style={{ background: "var(--purple)" }}
        ></div>

        <div
          className="blur s-blur2"
          style={{ background: "var(--purple)" }}
        ></div>
      </div>
    </div>
  );
};

export default Services;
